@font-face {
  font-family: "SofiaPro-Bold";
  src: url("../src/assets/fonts/SofiaPro-Bold.ttf") format("ttf");
  src: url("../src/assets/fonts/SofiaPro-Bold.woff") format("woff");
}
@font-face {
  font-family: "SofiaPro-SemiBold";
  src: url("../src/assets/fonts/SofiaPro-SemiBold.ttf") format("ttf");
  src: url("../src/assets/fonts/SofiaPro-SemiBold.woff") format("woff");
}
@font-face {
  font-family: "SofiaPro-Regular";
  src: url("../src/assets/fonts/SofiaPro-Regular.ttf") format("ttf");
  src: url("../src/assets/fonts/SofiaPro-Regular.woff") format("woff");
}
@font-face {
  font-family: "SofiaPro-Medium";
  src: url("../src/assets/fonts/SofiaPro-Medium.ttf") format("ttf");
  src: url("../src/assets/fonts/SofiaPro-Medium.woff") format("woff");
}

body {
  background-color: #042058;
}

.App {
  overflow-x: hidden;
  width: 100%;
  height: 100%;
}
.mountain-name-display {
  display: flex;
  align-items: center;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.regions button {
  cursor: pointer;
  color: rgb(206, 209, 217);
  font-size: 1.2rem;
  font-weight: 600;
  background: none;
  padding: 0.5rem 1rem;
  border: 0rem;
}
.regions button.is-selected {
  color: #fff;
}

.infoBox {
  overflow: hidden !important;
  width: max-content !important;
  height: auto !important;
  border-radius: 8px !important;
}
.infoBox img {
  display: none !important;
}
#mountain-description {
  padding: 0.9rem !important;
}
#container-mountain {
  margin-bottom: 0.9rem !important;
  display: flex !important;
  align-items: center !important;
}
#container-mountain-icon {
  width: 45px !important;
}
#mountain-icon {
  display: block !important;
}
#container-mountain-description {
  padding-left: 1rem !important;
}
.gm-style-iw {
  padding: 0px !important;
  max-width: max-content !important;
  max-height: max-content !important;
}
.gm-style-iw-d {
  overflow: auto !important;
  max-height: 414px !important;
}
.gm-style-iw-d div div {
  padding: 0px !important;
  border: none !important;
}
.gm-ui-hover-effect {
  right: 0px !important;
  top: 1px !important;
  width: 50px !important;
  height: 50px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.gm-ui-hover-effect span {
  margin: 16px 0px 7px !important;
  height: 41px !important;
  width: 34px !important;
}
.gm-style-iw-d div div h3 {
  font-family: "SofiaPro-Bold", Arial, Helvetica, sans-serif;
  font-size: 1.25rem !important;
  color: #042058;
  margin-bottom: 0px !important;
}
.gm-style-iw-d div div p {
  font-size: 1rem;
  font-family: "SofiaPro-Medium", Arial, Helvetica, sans-serif;
  color: #5b637a;
  opacity: 1;
  cursor: pointer;
  margin-bottom: 0px !important;
}
.gm-style .gm-style-iw-tc::after {
  display: none !important;
}

.btn {
  color: #fff !important;
  font-family: "SofiaPro-Bold", Arial, Helvetica, sans-serif;
  font-weight: bold !important;
  font-size: 16px !important;
  text-decoration: none !important;
  width: 100%;
  padding: 0.9rem 0.5rem !important;
  border-radius: 8px !important;
  background: #042058 !important;
}
.donate #container-mountain-description {
  padding-left: 0 !important;
}
.donate #container-mountain-description div {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.donate #container-mountain-description div h3 {
  margin-left: 1rem;
}
.donate #container-mountain-description p {
  font-family: "SofiaPro-Regular", Arial, Helvetica, sans-serif;
  line-height: 23px;
}

.btn::after {
  display: inline-block;
  position: relative;
  width: 0;
  height: 0;
  content: "";
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 11px solid #43bf6d;
  top: 1px;
  margin-left: 15px;
}
.area-item {
  color: #fff;
  font-family: "SofiaPro-Regular", Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  text-decoration: none;
}
.area-item:hover {
  color: #fff;
}

.area-item-gray {
  font-weight: 600;
  font-size: 1.125rem;
  color: #808080;
  text-decoration: none;
}
.area-item-gray:hover {
  color: #808080;
}
.cluster div {
  background: #042058 !important;
  color: #fff !important;
  border-radius: 50% !important;
  font-size: 24px !important;
}

.region-list {
  column-count: 1;
  column-gap: 1rem;
  padding: 0;
}
.region-list li {
  list-style: none;
}

.info-modal {
  position: fixed;
  top: 20vh;
  left: 50%;
  transform: translate(-50%);
  z-index: 10;
  text-align: center;
  width: 90%;
  padding: 1rem;
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.info-modal.donate .actions {
  margin-bottom: 1.5rem;
}
.info-modal.donate .actions img {
  float: right;
  cursor: pointer;
  width: 34px;
  height: 34px;
}
.info-modal.donate #container-mountain-description {
  display: inline-flex;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100vh;
}

@media (min-width: 618px) {
  .info-modal {
    width: 30rem;
    left: calc(50% - 15rem);
    transform: translate(0);
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .region-list {
    column-count: 2;
  }
}

@media (min-width: 992px) {
  .region-list {
    column-count: 3;
  }
}
